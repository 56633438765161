<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <!-- settings section -->
            <section class="settings-section section-gap-30">
                <SettingMenu></SettingMenu>
                <div class="setting-content">
                    <div class="section-title-wrap mb-0 d-flex justify-content-between">
                        <div class="section-title margin-deliver">
                            {{ $t(`Activity Log`) }}
                        </div>
                    </div>
                    <form class="form-filter candidates-filter flex-wrap">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <multiselect
                                            id="status"
                                            v-model="logType"
                                            :options="logTypes"
                                            :placeholder="$t('Log Type')"
                                            selectLabel=""
                                            deselectLabel=""
                                            label="name"
                                            track-by="id"
                                            @input="getCompanyLog"
                                    ></multiselect>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="data-table user-table" v-if="isContentLoading">
                        <TableLoader></TableLoader>
                    </div>
                    <div class="data-table user-table" v-else>
                        <h4 class="empty-message" v-show="isEmpty">{{ $t(`No logs found.`) }}</h4>
                        <div class="table-wrap" v-show="!isEmpty">
                            <div class="table">
                                <div class="table__row table__head">
                                    <div class="table-cell">{{ $t(`Date`) }}</div>
                                    <div class="table-cell">{{ $t(`Description`) }}</div>
                                    <div class="table-cell">{{ $t(`Action`) }}</div>
                                </div>
                                <div class="table__row" v-for="(log, index) in logs" :key="index">
                                    <div class="table-cell" style="width: 260px;">
                                        <div class="d-flex align-items-center">
                                            <div class="log-image">
                                                <img :src="log.image_url" alt="S M ISLAM" class="w-100 img-fluid">
                                            </div>
                                            {{ log.created_at }}
                                        </div>
                                    </div>
                                    <div class="table-cell activity-cell">
                                        <p v-html="$t(log.description, log.keys)"></p>
                                        <div class="d-flex  flex-wrap align-items-center" v-if="log.model !== 'CANDIDATE' && log.fields.length>0" >
                                            {{ $t(`Updated fields`) }}: <code class="semi-role code ml-1" v-for="field in log.fields"> {{ $t(field) }} </code>
                                        </div>
                                    </div>
                                    <div class="table-cell user-action">
                                        <div class="d-flex flex-column align-items-start">
                                            <span class="semi-button-primary log-label">{{ $t(log.model) }}</span>
                                            <span class="log-label" :class="`semi-button-${log.log_class}`">{{ $t(log.logname) }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <pagination v-if="!isEmpty" :pagination="paginateData" @paginate="getCompanyLog"></pagination>
                    </div>
                </div>
            </section>

        </div>
    </dashboard-layout>
</template>
<script>
import DashboardLayout from "../../../layouts/DashboardLayout";
import SettingMenu from "../../../components/company/SettingSidebar";
import client from "../../../app/api/Client";
import TableLoader from "../../../components/_loaders/_TableLoader";
import Pagination from "../../../components/pagination/Pagination";
import Multiselect from 'vue-multiselect';


export default {
    components: {
        DashboardLayout,
        SettingMenu,
        TableLoader,
        Pagination,
        Multiselect
    },

    data() {
        return {
            logs: [],
            isContentLoading: true,
            paginateData: [],
            logType: null,
            logTypes: [
                {
                    id: 'JOB',
                    name: 'Job',
                },
                {
                    id: 'COMPANY',
                    name: 'Company',
                },
                {
                    id: 'CANDIDATE',
                    name: 'Candidate',
                }
            ]
        }
    },

    computed: {
        isEmpty() {
            return !this.logs.length;
        }
    },
    methods: {
        async getCompanyLog(page) {
            if (typeof page === 'undefined') page = 1;
            try {
                let {data: {data}} = await client().get(`/company/setting/activity-log`, {
                    params: {
                        log_type: _.isNull(this.logType) || _.isEmpty(this.logType) ? '' : this.logType.id,
                        page: page
                    }
                });
                this.paginateData = data;
                this.logs = data.data;
            } catch (error) {
            }
            this.isContentLoading = false;

        },
    },
    async mounted() {
        await this.getCompanyLog();
    }
}
</script>

<style scoped>
.log-image {
    height: 30px;
    width: 30px;
    min-width: 30px;
    overflow: hidden;
    border-radius: 50px;
    margin-right: 10px;
}

.log-label {
    padding: 2px 10px;
    margin-bottom: 5px;
    border-radius: 30px;
}

code {
    padding: 3px 5px;
    font-size: 12px;
    border-radius: 20px;
    margin: 6px 0;
    display: inline-block;
    background: rgba(89, 125, 252, 0.15);
    color: #597dfc;
}

</style>

<style>
span.multiselect__single {
    background: transparent;
}

.log-label {
    padding: 2px 10px;
    margin-bottom: 5px;
    border-radius: 30px;
}

.content-area__body .data-table .table-wrap .table .table__row .table-cell.activity-cell * {
    word-break: inherit !important;
}
</style>
